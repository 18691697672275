import React, { useState } from "react";
import { Formik } from "formik";
import { FaEnvelope } from "react-icons/fa";
import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import "../styles/contact.scss";

const ContactPage = ({ data }) => {
  const pageData = data.sanityContact;
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  return (
    <Layout pageTitle="Contact">
      {pageData._rawText !== null && (
        <div className="text">
          <BlockContent blocks={pageData._rawText} />
        </div>
      )}
      <Formik
        initialValues={{ email: "", message: "" }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.message) {
            errors.message = "Message is required";
          }

          return errors;
        }}
        onSubmit={(values, actions) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...values }),
          })
            .then(() => {
              setFormSubmitSuccess(true);
              actions.resetForm();
            })
            .catch(() => {
              alert("Something went wrong, please try again");
            })
            .finally(() => actions.setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} name="contact" data-netlify={true}>
            <div className="field">
              <label className="label" htmlFor="email">
                Email
              </label>
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="email address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <span className="icon is-small is-left">
                  <FaEnvelope />
                </span>
              </div>
              {errors.email && touched.email && (
                <p className="help is-danger">{errors.email}</p>
              )}
            </div>

            <div className="field">
              <label className="label" htmlFor="message">
                Message
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  name="message"
                  placeholder="message"
                  id="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
              </div>
              {errors.message && touched.message && (
                <p className="help is-danger">{errors.message}</p>
              )}
            </div>

            <div className="field submit">
              <div className="control">
                {formSubmitSuccess ? (
                  <div className="submit-success">
                    Thanks! We'll be in touch soon
                  </div>
                ) : (
                  <button
                    className="button is-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Layout>
  );
};

export const query = graphql`
  {
    sanityContact(_id: { eq: "single-contact" }) {
      _rawText
    }
  }
`;

export default ContactPage;
